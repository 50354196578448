import React from "react";
import ComponentWow from "../../Wow";
import Form from "../../Form/Contact";
import { useTranslation } from "gatsby-plugin-react-i18next";

interface IDetail {
  title: string;
  desc: string;
  bgType: string;
}

const Detail: React.FC<IDetail> = ({ title, desc, bgType }) => {
  const { t } = useTranslation();

  return (
    <>
      <ComponentWow>
        <div className={`container-fluid ${bgType} py-5`}>
          <div className="container py-5">
            <div className="row align-items-center">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <h1 className="mb-4 section-title text-white">{title}</h1>
                <p className="text-white">{desc}</p>
              </div>
            </div>
          </div>
        </div>
      </ComponentWow>
      <ComponentWow>
        <div className="container mt-5 mb-2">
          <div className="row">
            <div className="col-md-2" />
            <div className="col-md-8">
              <h3 className="mb-4 text-center">{t("market.detail.info")}</h3>
              <div className="col-sm-12 col-md-12">
                <Form />
              </div>
            </div>
            <div className="col-md-2" />
          </div>
        </div>
      </ComponentWow>
    </>
  );
};

export default Detail;
